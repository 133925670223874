<template>
  <div class="dashboard-table__head">
    <table-head-row>
      <table-cell :width="80">
        <app-checkbox
          :current-value="isAllApplicationsSelected"
          id="all-applications"
          name="all-applications"
          :value="isAllApplicationsSelected"
          @updateValue="updateSelectedApplications"
          v-if="items.length"
        />
      </table-cell>
      <table-cell :width="105" is-bold> Опции </table-cell>
      <table-cell v-for="cell in cellsToDisplay" :width="cellsWidths[cell]" :key="cell" is-bold>
        <slot :name="cell" :cell="cell" :text="getTableCellText(cell)" v-if="$scopedSlots[cell]" />

        <template v-else> {{ getTableCellText(cell) }} </template>
      </table-cell>
      <table-cell :width="32" is-last is-inherit-cell-width />
    </table-head-row>
  </div>
</template>

<script>
import { tableCells, cellsWidths } from '@/utils/dashboardTable'
import AppCheckbox from '@/components/dump/AppCheckbox'
import TableCell from '@/components/smart/Dashboard/DashboardTable/TableCell'
import TableHeadRow from '@/components/smart/Dashboard/DashboardTable/TableHeadRow'

export default {
  components: { TableHeadRow, TableCell, AppCheckbox },
  props: {
    cellsToDisplay: {
      type: Array,
      required: true
    },
    isAllApplicationsSelected: {
      type: Boolean
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    getTableCellText(key) {
      return tableCells[key]
    },
    updateSelectedApplications(val) {
      this.$emit('updateSelectedApplications', val)
    }
  },
  name: 'TableHead',
  computed: {
    cellsWidths: () => cellsWidths
  }
}
</script>

<style scoped lang="sass"></style>
