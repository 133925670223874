<template>
  <div class="table-body">
    <template v-if="isLoading">
      <table-body-row-skeleton v-for="n in 13" :cells-to-display="cellsToDisplay" :key="n" />
    </template>
    <template v-else>
      <template v-if="items.length">
        <table-body-row
          :is-loading="isLoading"
          v-for="item in items"
          :key="item.hash"
          :item="item"
          :cells-to-display="cellsToDisplay"
          :selected-applications="selectedApplications"
          @updateSelectedApplications="updateSelectedApplications"
        >
          <template #cipher="{ val, isSpecialCalculationType }">
            <i v-if="isSpecialCalculationType"> {{ normalizeCellValue(val) }} </i>
            <template v-else> {{ normalizeCellValue(val) }} </template>
          </template>
          <template #objectName="{ val, isSpecialCalculationType, calculationType }">
            <ObjectNameView :calculation-type="calculationType" v-if="isSpecialCalculationType">
              {{ normalizeCellValue(val) }}
            </ObjectNameView>
            <template v-else>
              {{ normalizeCellValue(val) }}
            </template>
          </template>
          <template #status="{ val }">
            <project-status :status="val" />
          </template>
        </table-body-row>
      </template>
      <div class="table-body__notify" v-else>
        {{ notifyMessage }}
      </div>
    </template>
  </div>
</template>

<script>
import TableBodyRow from '@/components/smart/Dashboard/DashboardTable/TableBodyRow'
import ProjectStatus from '@/components/smart/Dashboard/DashboardTable/ProjectStatus'
import TableBodyRowSkeleton from '@/components/smart/Dashboard/DashboardTable/TableBodyRowSkeleton'
import ObjectNameView from '@/components/smart/Dashboard/DashboardTable/ObjectNameView'
import { isEmpty } from 'lodash'

export default {
  components: { ObjectNameView, TableBodyRowSkeleton, ProjectStatus, TableBodyRow },
  props: {
    cellsToDisplay: {
      type: Array,
      required: true
    },
    selectedApplications: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    isLoading: Boolean,
    searchQuery: {
      type: String,
      default: null
    }
  },
  name: 'TableBody',
  methods: {
    updateSelectedApplications(val) {
      this.$emit('updateSelectedApplications', val)
    },
    normalizeCellValue(val) {
      return val || '—'
    }
  },
  computed: {
    isSearchByCellActive() {
      return !!this.searchQuery
    },
    notifyMessage() {
      const { isSearchByCellActive, items } = this
      const [notifyMessage] = [
        isSearchByCellActive && isEmpty(items) && 'Совпадений не найдено',
        isEmpty(items) && 'В заданном диапазоне дат расчеты отсутствуют'
      ].filter(Boolean)
      return notifyMessage
    }
  }
}
</script>

<style scoped lang="sass">
.table-body
  padding-bottom: rem(16)
  margin-bottom: rem(16)
  &__notify
    padding-top: rem(16)
    margin-top: rem(16)
    text-align: center
    color: $red
</style>
