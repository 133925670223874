<template>
  <div class="app-checkbox">
    <input
      class="app-checkbox__input"
      type="checkbox"
      v-model="computedCurrentValue"
      :value="value"
      :id="`app-checkbox--${id}`"
      :name="name"
      hidden
    />
    <label :for="`app-checkbox--${id}`" class="app-checkbox__label">
      <span class="app-checkbox__label-text" v-if="$slots.default">
        <slot />
      </span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String, Boolean],
      required: true
    },
    id: {
      type: [String, Number],
      required: true
    },
    name: {
      type: String,
      required: true
    },
    currentValue: {
      type: [Number, String, Array, Boolean],
      required: true
    }
  },
  name: 'AppCheckbox',
  computed: {
    computedCurrentValue: {
      get() {
        return this.currentValue
      },
      set(val) {
        this.$emit('updateValue', val)
      }
    }
  }
}
</script>

<style scoped lang="sass">
.app-checkbox
  padding: rem(8) rem(10)
  text-align: left
  box-sizing: border-box
  &__input
    position: absolute
    opacity: 0
    display: none
    visibility: hidden
    pointer-events: none
    &:checked
      + .app-checkbox__label
          &:before
            background-color: $red
            border-color: $red
          &:after
            background-image: url("~@/assets/img/form-tick.svg")
            opacity: 1
          @media (any-hover: hover)
            &:hover
              opacity: .5
              &:after
                background-image: url("~@/assets/img/icons/app-checkbox--cross.svg")
  &__label
    display: flex
    align-items: center
    cursor: pointer
    position: relative
    // transition: .5s
    &-text
      margin-left: rem(10)
      font-style: normal
      font-weight: 600
      font-size: rem(14)
      line-height: rem(16)
      color: $black

    &:after
      opacity: 0
      pointer-events: none
      top: 50%
      left: 0
      transform: translateY(-50%)
      content: ""
      position: absolute
      height: rem(16)
      width: rem(16)
      // transition: .5s
      background-repeat: no-repeat
      background-position: center center
    &:before
      content: ""
      position: relative
      display: block
      height: rem(16)
      width: rem(16)
      border: rem(1) solid $cool_A
      background-color: $white
      transition: .5s
      border-radius: rem(4)
      box-sizing: border-box
    @media(any-hover: hover)
      &:hover
        &:before
          border-color: $black_C
        &:after
          background-image: url("~@/assets/img/icons/app-checkbox--plus.svg")
          opacity: 1
</style>
