<template>
  <span class="content">
    <img :src="iconPath" alt="icon" class="content__icon" />
    <i class="content__text">
      <slot />
    </i>
  </span>
</template>

<script>
export default {
  props: {
    calculationType: {
      type: [String, Number],
      required: true
    }
  },
  name: 'ObjectNameView',
  computed: {
    iconPath() {
      const iconsSources = {
        373: require('@/assets/img/icons/dashboard/correction-icon.svg'),
        374: require('@/assets/img/icons/dashboard/error-icon.svg')
      }
      return iconsSources[this.calculationType]
    }
  }
}
</script>

<style scoped lang="sass">
.content
  line-height: initial
  &__icon
    margin-right: rem(10)
  &__icon, &__text
    vertical-align: middle
</style>
