<template>
  <div class="dashboard-head">
    <app-button @click="openCalendar" is-grey>
      <template #icon>
        <inline-svg :src="require('@/assets/img/icons/dashboard/calendar-icon.svg')" />
      </template>
      {{ convertedRange.start }}
      <img src="@/assets/img/icons/dashboard/date-trigger-arrow.svg" alt="→" class="arrow" />
      {{ convertedRange.end }}
    </app-button>
    <customize-cells @updateActiveCells="updateActiveCells" :active-cells="activeCells" />
    <app-button color="red" @click="getRegistry" :is-loading="isLoading">
      <template #icon>
        <inline-svg :src="require('@/assets/img/icons/dashboard/register.svg')" />
      </template>
      Сформировать реестр
    </app-button>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import CustomizeCells from '@/components/smart/Dashboard/CustomizeCells'
import AppButton from '@/components/elements/AppButton'

export default {
  name: 'DashboardHead',
  components: {
    AppButton,
    CustomizeCells,
    InlineSvg
  },
  props: {
    activeCells: {
      type: Array,
      required: true
    },
    convertedRange: {
      type: Object
    },
    isLoading: {
      type: Boolean
    }
  },
  methods: {
    updateActiveCells(val) {
      this.$emit('updateActiveCells', val)
    },
    openCalendar() {
      this.$emit('openCalendar')
    },
    getRegistry() {
      this.$emit('getRegistry')
    }
  }
}
</script>

<style scoped lang="sass">
.dashboard-head
  display: flex
  align-items: center
  justify-content: flex-end
  flex-wrap: wrap
  gap: rem(4)
  max-width: rem(1600)
  margin: rem(32) auto
  width: calc(100% / 19 * 16)
  .app-button
    .arrow
      margin: 0 rem(20)
</style>
