var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard__body"},[_c('div',{staticClass:"dashboard__table"},[_c('table-head',{attrs:{"cells-to-display":_vm.cellsToDisplay,"is-all-applications-selected":_vm.isAllApplicationsSelected,"items":_vm.items},on:{"updateSelectedApplications":_vm.toggleAllApplications},scopedSlots:_vm._u([{key:"applicationNumber",fn:function(ref){
var cell = ref.cell;
var text = ref.text;
return [_c('table-head-item-with-search',{attrs:{"cell":cell,"text":text,"search-query":_vm.searchQuery,"active-search-cell":_vm.activeSearchCell},on:{"updateSearchQuery":_vm.updateSearchQuery,"updateActiveSearchCell":_vm.setActiveSearchCell,"dismissSearch":_vm.dismissSearch}})]}},{key:"cipher",fn:function(ref){
var cell = ref.cell;
var text = ref.text;
return [_c('table-head-item-with-search',{attrs:{"cell":cell,"text":text,"search-query":_vm.searchQuery,"active-search-cell":_vm.activeSearchCell},on:{"updateSearchQuery":_vm.updateSearchQuery,"updateActiveSearchCell":_vm.setActiveSearchCell,"dismissSearch":_vm.dismissSearch}})]}},{key:"objectName",fn:function(ref){
var cell = ref.cell;
var text = ref.text;
return [_c('table-head-item-with-search',{attrs:{"cell":cell,"text":text,"search-query":_vm.searchQuery,"active-search-cell":_vm.activeSearchCell},on:{"updateSearchQuery":_vm.updateSearchQuery,"updateActiveSearchCell":_vm.setActiveSearchCell,"dismissSearch":_vm.dismissSearch}})]}},{key:"endDate",fn:function(ref){
var text = ref.text;
return [_c('span',{on:{"click":_vm.openCalendar}},[_vm._v(" "+_vm._s(text)+" ")])]}}])}),_c('table-body',{attrs:{"is-loading":_vm.isLoading,"cells-to-display":_vm.cellsToDisplay,"selected-applications":_vm.selectedApplications,"items":_vm.items,"search-query":_vm.searchQuery},on:{"updateSelectedApplications":_vm.updateSelectedApplications}})],1),_c('div',{staticClass:"dashboard__body-buttons"},[(_vm.selectedApplications.length)?_c('app-button',{attrs:{"color":"grey"},on:{"click":_vm.deleteApplications},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('inline-svg',{attrs:{"src":require('@/assets/img/icons/dashboard/delete.svg')}})]},proxy:true}],null,false,3345722113)},[_vm._v(" Удалить выбранные ")]):_vm._e(),_c('app-button',{attrs:{"color":"green"},on:{"click":_vm.newCalculation},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/img/icons/dashboard/new-calculate.svg"),"alt":"+"}})]},proxy:true}])},[_vm._v(" Новый расчет ПК ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }