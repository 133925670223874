export const cellsKeys = {
  status: 'status',
  cipher: 'cipher',
  applicationNumber: 'applicationNumber',
  objectName: 'objectName',
  endDate: 'endDate',
  customer: 'customer',
  calculationType: 'calculationType'
}

export const tableCells = {
  [cellsKeys.applicationNumber]: '№ заявки',
  [cellsKeys.cipher]: 'Шифр расчета',
  [cellsKeys.objectName]: 'Название объекта',
  [cellsKeys.endDate]: 'Дата расчета',
  [cellsKeys.status]: 'Статус',
  [cellsKeys.customer]: 'Заказчик',
  [cellsKeys.calculationType]: 'Тип расчета'
}

export const cellsWidths = {
  [cellsKeys.applicationNumber]: 155, // 130 115
  [cellsKeys.cipher]: 410,
  [cellsKeys.objectName]: 300, // 400
  [cellsKeys.endDate]: 155,
  [cellsKeys.status]: 144,
  [cellsKeys.customer]: 200,
  [cellsKeys.calculationType]: 250 // 170
}
