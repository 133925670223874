<template>
  <div class="options-dropdown">
    <app-button v-for="option in options" :key="option.action" @click="handle(option.action)">
      <template #icon>
        <inline-svg :src="option.icon" />
      </template>
      {{ option.text }}
    </app-button>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import AppButton from '@/components/elements/AppButton'
import { copyCalculation } from '@/api/moderator'
import { handleError, generateCipher } from '@/utils/deepSettings'
import { mapState } from 'vuex'
import { cloneDeep } from 'lodash'
const actions = {
  edit: 'edit',
  correct: 'correction',
  error: 'error',
  clone: 'clone'
}

const options = [
  {
    action: actions.edit,
    icon: require('@/assets/img/icons/dashboard/options-edit.svg'),
    text: 'Редактирование'
  },
  {
    action: actions.correct,
    icon: require('@/assets/img/icons/dashboard/options-correct.svg'),
    text: 'Корректировка'
  },
  {
    action: actions.error,
    icon: require('@/assets/img/icons/dashboard/options-error.svg'),
    text: 'Ошибка'
  },
  {
    action: actions.clone,
    icon: require('@/assets/img/icons/dashboard/options-copy.svg'),
    text: 'Добавить вариант'
  }
]

export default {
  name: 'OptionsDropDown',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  components: { AppButton, InlineSvg },
  methods: {
    handle(action) {
      const { $i18n, userId, item, calculationTypes } = this
      const hash = item.hash
      const lang = $i18n.locale
      const updateCipher = true
      const DeepSettings = cloneDeep(item)
      const calcType = DeepSettings.applicationData.calculationType
      const checkType =
        calcType && calculationTypes.find(ct => ct.id === calcType)
          ? calculationTypes.find(ct => ct.id === calcType).name !== 'Новый расчёт'
          : true
      if (action === actions.edit) {
        this.$router.push({
          name: 'Result',
          params: { lang },
          query: { object: item.hash }
        })
      } else {
        if (!(action === actions.clone) || checkType) {
          DeepSettings.applicationData.cloneIndex = item.applicationData.cloneIndex + 1
          DeepSettings.applicationData.cipher = generateCipher(
            userId,
            DeepSettings.applicationData.calculationNumber,
            DeepSettings.applicationData.endDate,
            DeepSettings.calculationPrice,
            DeepSettings.applicationData.calculationType,
            DeepSettings.applicationData.variantNumber,
            DeepSettings.applicationData.variantsCount,
            DeepSettings.applicationData.cloneIndex,
            calculationTypes,
            updateCipher
          )
        }
        copyCalculation({
          lang,
          userId,
          hash,
          action,
          DeepSettings
        })
          .then(response => {
            const hash = response.data
            this.$router.push({ name: 'DeepSettings', params: { lang, hash } })
          })
          .catch(e => {
            this.closeModal()
            handleError(this.$notify, e)
          })
      }
    }
  },
  computed: {
    ...mapState('moderator', {
      userId: state => state.userId,
      calculationTypes: state => state.calculationTypes
    }),
    options: () => options
  }
}
</script>

<style scoped lang="sass">
.options-dropdown
  padding: rem(5)
  .app-button
    width: 100%
</style>
