<template>
  <div class="dashboard__body">
    <div class="dashboard__table">
      <table-head
        :cells-to-display="cellsToDisplay"
        :is-all-applications-selected="isAllApplicationsSelected"
        @updateSelectedApplications="toggleAllApplications"
        :items="items"
      >
        <template #applicationNumber="{ cell, text }">
          <table-head-item-with-search
            :cell="cell"
            :text="text"
            :search-query="searchQuery"
            :active-search-cell="activeSearchCell"
            @updateSearchQuery="updateSearchQuery"
            @updateActiveSearchCell="setActiveSearchCell"
            @dismissSearch="dismissSearch"
          />
        </template>
        <template #cipher="{ cell, text }">
          <table-head-item-with-search
            :cell="cell"
            :text="text"
            :search-query="searchQuery"
            :active-search-cell="activeSearchCell"
            @updateSearchQuery="updateSearchQuery"
            @updateActiveSearchCell="setActiveSearchCell"
            @dismissSearch="dismissSearch"
          />
        </template>
        <template #objectName="{ cell, text }">
          <table-head-item-with-search
            :cell="cell"
            :text="text"
            :search-query="searchQuery"
            :active-search-cell="activeSearchCell"
            @updateSearchQuery="updateSearchQuery"
            @updateActiveSearchCell="setActiveSearchCell"
            @dismissSearch="dismissSearch"
          />
        </template>
        <template #endDate="{ text }">
          <span @click="openCalendar">
            {{ text }}
          </span>
        </template>
      </table-head>
      <table-body
        :is-loading="isLoading"
        :cells-to-display="cellsToDisplay"
        :selected-applications="selectedApplications"
        :items="items"
        :search-query="searchQuery"
        @updateSelectedApplications="updateSelectedApplications"
      />
    </div>
    <div class="dashboard__body-buttons">
      <app-button @click="deleteApplications" v-if="selectedApplications.length" color="grey">
        <template #icon>
          <inline-svg :src="require('@/assets/img/icons/dashboard/delete.svg')" />
        </template>
        Удалить выбранные
      </app-button>
      <app-button color="green" @click="newCalculation">
        <template #icon>
          <img src="@/assets/img/icons/dashboard/new-calculate.svg" alt="+" />
        </template>
        Новый расчет ПК
      </app-button>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import { mapMutations } from 'vuex'
import TableHead from '@/components/smart/Dashboard/DashboardTable/TableHead'
import TableBody from '@/components/smart/Dashboard/DashboardTable/TableBody'
import AppButton from '@/components/elements/AppButton'
import TableHeadItemWithSearch from '@/components/smart/Dashboard/DashboardTable/TableHeadItemWithSearch'
export default {
  components: { TableHeadItemWithSearch, AppButton, TableBody, TableHead, InlineSvg },
  props: {
    cellsToDisplay: {
      type: Array,
      required: true
    },
    isLoading: Boolean,
    items: {
      type: Array,
      required: true
    },
    searchQuery: {
      type: String,
      default: null
    },
    activeSearchCell: {
      type: String,
      default: null
    },
    selectedApplications: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    isAllApplicationsSelected: false
  }),
  methods: {
    ...mapMutations({
      RESET_SECTORS: 'RESET_SECTORS'
    }),
    toggleAllApplications(val) {
      this.isAllApplicationsSelected = val
      this.$emit('update:selectedApplications', val ? this.allApplicationsToSelect : [])
      // this.selectedApplications = val ? this.allApplicationsToSelect : []
      // this.selectedApplications = val
    },
    updateSearchQuery(val) {
      this.$emit('update:searchQuery', val)
    },
    setActiveSearchCell(cell) {
      this.$emit('update:searchQuery', null)
      this.$emit('update:activeSearchCell', cell)
    },
    dismissSearch() {
      this.$emit('update:searchQuery', null)
      this.$emit('update:activeSearchCell', null)
    },
    updateSelectedApplications(val) {
      this.unselectAllApplications()
      this.$emit('update:selectedApplications', val)
      // this.selectedApplications = val
    },
    deleteApplications() {
      this.$emit('deleteApplications', this.selectedApplications)
      this.$emit('update:selectedApplications', [])
      // this.selectedApplications = []
      this.unselectAllApplications()
    },
    unselectAllApplications() {
      if (this.isAllApplicationsSelected) {
        this.isAllApplicationsSelected = false
      }
    },
    openCalendar() {
      this.$emit('openCalendar')
    },
    newCalculation() {
      this.RESET_SECTORS()
      this.$router.push('/')
    }
  },
  name: 'DashboardTable',
  computed: {
    allApplicationsToSelect() {
      return this.items.map(item => item.hash)
    }
  }
}
</script>

<style scoped lang="sass">
.dashboard
  &__table
    max-width: rem(1600)
    width: calc(100% / 19 * 16)
    // padding: 0 calc(100% / 19 * 1.5)
    margin: 0 auto
    overflow-x: auto
    scrollbar-width: thin
    scrollbar-color: rgba($red, .75) transparent
    &::-webkit-scrollbar
      width: rem(4)
      height: rem(6)
    &::-webkit-scrollbar-thumb
      background: $ec
    &::-webkit-scrollbar-thumb
      background-color: rgba($red, .75)
      border-radius: 0
    &::-webkit-scrollbar-track
      background: $ec
  &__body
    &-buttons
      margin: rem(32) auto
      flex-wrap: wrap
      display: flex
      justify-content: space-between
      max-width: rem(1600)
      width: calc(100% / 19 * 16)
      .app-button
        &:last-child
          margin-left: auto
</style>
