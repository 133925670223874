<template>
  <div :class="classes" :style="styles">
    <div class="table-cell__inner" :style="isInheritCellWidth ? styles : {}">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableCell',
  props: {
    width: {
      type: Number,
      required: true
    },
    isBold: Boolean,
    isLast: Boolean,
    isInheritCellWidth: Boolean
  },
  computed: {
    styles() {
      return { width: `${this.width}px` }
    },
    classes() {
      return {
        'table-cell': true,
        'table-cell--bold': this.isBold,
        'table-cell--last': this.isLast
      }
    }
  }
}
</script>

<style scoped lang="sass">
.table-cell
  flex-shrink: 0
  min-height: rem(48)
  @extend %TT14R
  color: $black
  display: flex
  align-items: center
  box-shadow: inset 0 rem(-1) 0 $ec
  &--bold
    font-weight: bold
  &--last
    flex: 1 1 auto
    justify-content: flex-end
    .table-cell
      &__inner
        padding: 0
        width: auto
  &__inner
    padding-right: rem(20)
    box-sizing: border-box
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    width: 100%
    line-height: initial
  .app-checkbox
    padding: rem(8)
    display: inline-flex
</style>
