<template>
  <div class="cells-customizer">
    <tippy
      interactive
      placement="bottom-start"
      distance="4"
      trigger="click"
      theme="light"
      appendTo="parent"
    >
      <template #trigger>
        <app-button is-grey>
          <template #icon>
            <inline-svg :src="require('@/assets/img/icons/customize-cells.svg')" />
          </template>
          Настроить столбцы
        </app-button>
      </template>
      <customize-cells-dropdown
        :active-cells="activeCells"
        @updateActiveCells="updateActiveCells"
      />
    </tippy>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import AppButton from '@/components/elements/AppButton'
import CustomizeCellsDropdown from '@/components/smart/Dashboard/CustomizeCellsDropdown'
export default {
  name: 'CustomizeCells',
  props: {
    activeCells: {
      type: Array,
      required: true
    }
  },
  components: { CustomizeCellsDropdown, AppButton, InlineSvg },
  methods: {
    updateActiveCells(val) {
      this.$emit('updateActiveCells', val)
    }
  }
}
</script>
<style lang="sass">
.cells-customizer
  .tippy-tooltip
    padding: 0
    background: $white
</style>
<style scoped lang="sass">
.cells-customizer
</style>
