<template>
  <section class="dashboard">
    <div class="dashboard__title">Мои расчеты</div>
    <dashboard-head
      :active-cells="activeCells"
      :converted-range="convertedRange"
      :is-loading="isRegistryButtonLoading"
      @updateActiveCells="updateActiveCells"
      @openCalendar="openCalendar"
      @getRegistry="getRegistry"
    />
    <dashboard-table
      :cells-to-display="filteredCells"
      :is-loading="isLoading"
      :items="foundedItems"
      :search-query.sync="searchQuery"
      :active-search-cell.sync="activeSearchCell"
      :selected-applications.sync="selectedApplications"
      @openCalendar="openCalendar"
      @deleteApplications="deleteApplications"
    />
    <modal :is-modal-open="isModalOpen" @close="closeModal">
      <template #body>
        <div class="modal-datepicker">
          <v-date-picker v-model="dateRange" :rows="1" :columns="2" color="red" is-range />
          <div class="modal-datepicker__footer">
            <div class="dates">
              {{ convertedRange.start }}
              <img
                src="@/assets/img/icons/dashboard/date-trigger-arrow--red.svg"
                alt="→"
                class="arrow"
              />
              {{ convertedRange.end }}
            </div>
            <simple-button color="red" @clickedFromSimpleBtn="requestWithNewRange" is-small>
              Подтверждаю
            </simple-button>
          </div>
        </div>
      </template>
    </modal>
  </section>
</template>

<script>
import { bodyBgMixin } from '@/mixins'
import { tableCells, cellsKeys } from '@/utils/dashboardTable'
import DashboardHead from '@/components/smart/Dashboard/DashboardHead'
import DashboardTable from '@/components/smart/Dashboard/DashboardTable'
import SimpleButton from '@/components/elements/Dom/Simple-button'
import { deleteApplications, getApplications, getRegistry } from '@/api/moderator'
import { convertISOtoDate } from '@/utils/DateConverters'
import { mapMutations, mapActions, mapState, mapGetters } from 'vuex'
import { handleError } from '@/utils/deepSettings'
import { saveAs } from 'file-saver'

const today = new Date()

export default {
  name: 'Dashboard',
  mixins: [bodyBgMixin],
  components: {
    SimpleButton,
    DashboardTable,
    DashboardHead,
    Modal: () => import('@/components/elements/Modals/Modal'),
    VDatePicker: () => import('v-calendar/lib/components/date-picker.umd')
  },
  data: () => ({
    isModalOpen: false,
    selectedApplications: [],
    isRegistryButtonLoading: false,
    activeCells: [
      cellsKeys.applicationNumber,
      cellsKeys.cipher,
      cellsKeys.objectName,
      cellsKeys.endDate,
      cellsKeys.status
    ],
    isLoading: true,
    items: [],
    stashedDateRange: {},
    dateRange: {
      start: new Date(today.setMonth(today.getMonth() - 1)),
      end: new Date()
    },
    searchQuery: null,
    activeSearchCell: null
  }),
  methods: {
    ...mapActions('moderator', {
      getDeepSettingsData: 'getDeepSettingsData',
      setDataRange: 'setDataRange',
      setActiveCells: 'setActiveCells'
    }),
    ...mapMutations('moderator', {
      RESTORE_DEFAULT_DEEP_SETTINGS: 'RESTORE_DEFAULT_DEEP_SETTINGS'
    }),
    getRegistry() {
      const { $i18n, selectedApplications } = this
      if (selectedApplications.length) {
        this.isRegistryButtonLoading = true
        getRegistry({
          lang: $i18n.locale,
          selectedApplications
        })
          .then(response => {
            this.isRegistryButtonLoading = false
            const file = new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            })
            const newFileName = `Реестр.xlsx`
            saveAs(file, newFileName)
          })
          .catch(e => {
            handleError(this.$notify, e)
            this.isRegistryButtonLoading = false
          })
      }
    },
    updateActiveCells(val) {
      this.activeCells = val
      this.setActiveCells(this.activeCells)
    },
    requestWithNewRange() {
      this.isModalOpen = false
      this.getApplicationsByRange()
      this.setDataRange(this.dateRange)
    },
    getApplicationsByRange() {
      if (!this.isLoading) {
        this.isLoading = true
      }
      if (this.items.length) {
        this.items = []
      }
      getApplications({
        lang: this.$i18n.locale,
        range: this.dateRange,
        userId: this.userId
      })
        .then(response => {
          const { data } = response
          const normalizedItems = data.map(item => {
            return {
              ...item.deepSettings,
              hash: item.hash,
              status: item.status,
              sectors: item.sectors,
              result: item.result
            }
          })
          this.isLoading = false
          this.items = normalizedItems
        })
        .catch(e => {
          handleError(this.$notify, e)
        })
    },
    closeModal() {
      this.isModalOpen = false
      this.dateRange = { ...this.stashedDateRange }
    },
    openCalendar() {
      this.stashedDateRange = { ...this.dateRange }
      this.isModalOpen = true
    },
    deleteApplications(selectedApplications) {
      this.isLoading = true
      deleteApplications(selectedApplications)
        .then(response => {
          if (response.data) {
            this.isLoading = false
            this.getApplicationsByRange()
          } else {
            this.isLoading = false
            handleError(this.$notify, 'при удалении возникла ошибка')
          }
        })
        .catch(e => {
          handleError(this.$notify, e)
        })
    }
  },
  mounted() {
    if (this.getDateRange.length === 0) {
      this.setDataRange(this.dateRange)
    } else {
      this.dateRange = this.getDateRange
    }
    if (this.getActiveCells.length === 0) {
      this.setActiveCells(this.activeCells)
    } else {
      this.activeCells = this.getActiveCells
    }
    this.$root.onBodyScroll(false)
    this.RESTORE_DEFAULT_DEEP_SETTINGS()
    this.getDeepSettingsData(this.$i18n.locale)
    this.getApplicationsByRange()
  },
  computed: {
    ...mapState('moderator', {
      userId: state => state.userId
    }),
    ...mapGetters('moderator', ['getDateRange', 'getActiveCells']),
    filteredCells() {
      return Object.keys(tableCells).filter(cell => this.activeCells.includes(cell))
    },
    convertedRange() {
      return Object.fromEntries(
        Object.entries(this.dateRange).map(([key, value]) => {
          return [key, convertISOtoDate(value)]
        })
      )
    },
    foundedItems() {
      const { searchQuery } = this
      if (searchQuery) {
        const { applicationNumber, cipher, objectName } = cellsKeys
        return this.items.filter(item => {
          const keyForSearch = {
            [applicationNumber]: item.applicationData[applicationNumber],
            [cipher]: item.applicationData[cipher],
            [objectName]: item.objectData.name
          }
          return String(keyForSearch[this.activeSearchCell])
            .toUpperCase()
            .includes(searchQuery.toUpperCase())
        })
      } else {
        return this.items
      }
    }
  }
}
</script>
<style scoped lang="sass">

.dashboard
  &__title
    width: calc(100% / 19 * 16)
    max-width: rem(1600)
    margin: rem(32) auto
    color: $black
    font-style: normal
    font-weight: normal
    font-size: rem(32)
    line-height: 100%
.modal-datepicker
  padding: rem(56) rem(70) rem(30)
  &::v-deep
    @extend %calendar-styles
    .vc-container
      .vc-arrows-container
        .vc-arrow
          &.is-right
            transform: translateX(40px)
          &.is-left
            transform: translateX(-40px)
  &__footer
    display: flex
    justify-content: space-between
    align-items: center
    gap: rem(20)
    flex-wrap: wrap
    padding-top: rem(36)
    box-shadow: inset 0 rem(1) 0 $ec
    .dates
      font-style: normal
      font-weight: bold
      font-size: rem(16)
      line-height: rem(16)
      color: $black
      .arrow
        margin: 0 rem(20)
</style>
