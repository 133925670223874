<template>
  <div class="table-row">
    <table-cell :width="80">
      <skeleton :width="18" :margin="8" />
    </table-cell>
    <table-cell :width="105">
      <skeleton :width="32" />
    </table-cell>
    <table-cell v-for="cell in cellsToDisplay" :width="cellsWidths[cell]" :key="cell">
      <skeleton />
    </table-cell>
    <table-cell :width="32" is-last>
      <skeleton :width="32" />
    </table-cell>
  </div>
</template>

<script>
import { cellsWidths } from '@/utils/dashboardTable'
import TableCell from '@/components/smart/Dashboard/DashboardTable/TableCell'
import Skeleton from '@/components/smart/Dashboard/DashboardTable/Skeleton'
export default {
  props: {
    cellsToDisplay: {
      type: Array,
      required: true
    }
  },
  name: 'TableBodyRowSkeleton',
  components: { Skeleton, TableCell },
  computed: {
    cellsWidths: () => cellsWidths
  }
}
</script>

<style scoped lang="sass">
.table-row
  display: flex
</style>
