<script>
export default {
  name: 'TableHeadRow',
  render(createElement) {
    return createElement('div', { class: 'table-row' }, this.$slots.default)
  }
}
</script>

<style scoped lang="sass">
.table-row
  display: flex
</style>
