<template>
  <div class="options-button">
    <div :name="`tippyMultiple--${item.hash}`" class="options-button__icon">
      <inline-svg :src="require('@/assets/img/icons/dashboard/options-button.svg')" />
    </div>
    <tippy :to="`tippyMultiple--${item.hash}`" arrow placement="top" multiple>
      <b>Настройка <br />расчета</b>
    </tippy>
    <tippy
      appendTo="parent"
      :to="`tippyMultiple--${item.hash}`"
      trigger="click"
      placement="bottom-start"
      multiple
      theme="light"
      offset="0, -42"
      interactive
    >
      <options-drop-down :item="item" />
    </tippy>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import OptionsDropDown from '@/components/smart/Dashboard/DashboardTable/OptionsDropDown'
export default {
  components: { OptionsDropDown, InlineSvg },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  name: 'OptionsButton'
}
</script>

<style lang="sass">
.options-button
  .tippy-tooltip
    padding: 0
    background: $white
</style>
<style scoped lang="sass">
.options-button
  display: inline-flex
  align-items: center
  justify-content: center
  cursor: pointer
  border-radius: rem(4)
  &__icon
    height: rem(32)
    width: rem(32)
    display: flex
    align-items: center
    justify-content: center
    transition: .3s
    color: $cool_A
    // filter: invert(93%) sepia(0%) saturate(97%) hue-rotate(176deg) brightness(98%) contrast(83%)
  @media(any-hover: hover)
    &:hover
      background: $ec
      .options-button
        &__icon
          color: $light-black
          // filter: invert(34%) sepia(6%) saturate(30%) hue-rotate(337deg) brightness(102%) contrast(92%)
</style>
