<template>
  <div class="list">
    <app-checkbox
      v-for="[cellKey, cellText] in tableCells"
      :key="cellKey"
      name="cell-checkbox"
      :id="cellKey"
      :current-value="activeCells"
      :value="cellKey"
      @updateValue="updateValue"
    >
      {{ cellText }}
    </app-checkbox>
  </div>
</template>

<script>
import { tableCells } from '@/utils/dashboardTable'
import AppCheckbox from '@/components/dump/AppCheckbox'

export default {
  name: 'CustomizeCellsDropdown',
  components: { AppCheckbox },
  props: {
    activeCells: {
      type: Array,
      required: true
    }
  },
  methods: {
    updateValue(val) {
      this.$emit('updateActiveCells', val)
    }
  },
  computed: {
    tableCells: () => Object.entries(tableCells)
  }
}
</script>

<style scoped lang="sass">
.list
  background: $white
  padding: rem(5)
  .app-checkbox
    width: rem(210)
</style>
