var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-body"},[(_vm.isLoading)?_vm._l((13),function(n){return _c('table-body-row-skeleton',{key:n,attrs:{"cells-to-display":_vm.cellsToDisplay}})}):[(_vm.items.length)?_vm._l((_vm.items),function(item){return _c('table-body-row',{key:item.hash,attrs:{"is-loading":_vm.isLoading,"item":item,"cells-to-display":_vm.cellsToDisplay,"selected-applications":_vm.selectedApplications},on:{"updateSelectedApplications":_vm.updateSelectedApplications},scopedSlots:_vm._u([{key:"cipher",fn:function(ref){
var val = ref.val;
var isSpecialCalculationType = ref.isSpecialCalculationType;
return [(isSpecialCalculationType)?_c('i',[_vm._v(" "+_vm._s(_vm.normalizeCellValue(val))+" ")]):[_vm._v(" "+_vm._s(_vm.normalizeCellValue(val))+" ")]]}},{key:"objectName",fn:function(ref){
var val = ref.val;
var isSpecialCalculationType = ref.isSpecialCalculationType;
var calculationType = ref.calculationType;
return [(isSpecialCalculationType)?_c('ObjectNameView',{attrs:{"calculation-type":calculationType}},[_vm._v(" "+_vm._s(_vm.normalizeCellValue(val))+" ")]):[_vm._v(" "+_vm._s(_vm.normalizeCellValue(val))+" ")]]}},{key:"status",fn:function(ref){
var val = ref.val;
return [_c('project-status',{attrs:{"status":val}})]}}],null,true)})}):_c('div',{staticClass:"table-body__notify"},[_vm._v(" "+_vm._s(_vm.notifyMessage)+" ")])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }