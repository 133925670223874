<template>
  <transition name="slide" mode="out-in">
    <dashboard-search
      :search-query="searchQuery"
      @updateSearchQuery="updateSearchQuery"
      @dismissSearch="dismissSearch"
      v-if="activeSearchCell === cell"
      ref="dashboardSearch"
    />
    <div class="search-title" v-else>
      {{ text }}
      <app-button @click="setActiveSearchCell" size="small">
        <template #icon>
          <inline-svg :src="require('@/assets/img/icons/dashboard/search.svg')" />
        </template>
      </app-button>
    </div>
  </transition>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
export default {
  name: 'TableHeadItemWithSearch',
  components: {
    InlineSvg,
    AppButton: () => import('@/components/elements/AppButton'),
    DashboardSearch: () => import('@/components/smart/Dashboard/DashboardTable/DashboardSearch')
  },
  props: {
    cell: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    searchQuery: {
      type: String,
      default: null
    },
    activeSearchCell: {
      type: String,
      default: null
    }
  },
  methods: {
    updateSearchQuery(val) {
      this.$emit('updateSearchQuery', val)
    },
    setActiveSearchCell() {
      this.$emit('updateActiveSearchCell', this.cell)
    },
    // handleEnter() {
    //   const target = this.$refs.dashboardSearch
    //   if (target) target.focusInput()
    // },
    dismissSearch() {
      this.$emit('dismissSearch')
    }
  }
}
</script>

<style scoped lang="sass">
.search-title
  display: flex
  align-items: center
  .app-button
    // width: rem(30)
    // height: rem(30)
    margin-left: rem(8)
.slide-enter-active, .slide-leave-active
  transition: .3s
.slide-enter, .slide-leave-to
  transform: translateY(100%)
</style>
