<template>
  <div class="table-row">
    <table-cell :width="80">
      <app-checkbox
        :current-value="selectedApplications"
        :id="`application-${item.hash}`"
        :name="`application-${item.hash}`"
        :value="item.hash"
        @updateValue="updateSelectedApplications"
      />
    </table-cell>
    <table-cell :width="105">
      <options-button :item="item" />
    </table-cell>
    <table-cell v-for="[key, val] in activeItemCells" :width="cellsWidths[key]" :key="key">
      <slot
        :name="key"
        :is-special-calculation-type="isSpecialCalculationType"
        :calculation-type="item.applicationData.calculationType"
        :val="val"
        v-if="$scopedSlots[key]"
      />
      <template v-else>{{ val ? val : '—' }}</template>
    </table-cell>
    <table-cell :width="64" is-last>
      <div class="table-btns">
        <div class="table-btn">
          <tippy :to="`tippy-share--${item.hash}`" arrow placement="top">
            <b>Поделиться</b>
          </tippy>
          <app-button
            transparent
            size="small"
            @click="showMethod"
            :name="`tippy-share--${item.hash}`"
          >
            <template #icon>
              <inline-svg :src="require('@/assets/img/icons/dashboard/share.svg')" />
            </template>
          </app-button>
        </div>
        <div class="table-btn">
          <tippy :to="`tippy-price--${item.hash}`" arrow placement="top">
            <b>Настройка <br />стоимости</b>
          </tippy>
          <app-button
            transparent
            size="small"
            @click="goToDeepSettings"
            :name="`tippy-price--${item.hash}`"
          >
            <template #icon>
              <inline-svg :src="require('@/assets/img/icons/dashboard/deep-settings.svg')" />
            </template>
          </app-button>
        </div>
      </div>
    </table-cell>
    <modal :is-modal-open="isModalOpen" :is-show-close="false" @close="closeModal">
      <template #body>
        <share-modal-content
          :url-hash="item.hash"
          :is-loading="modalIsLoading"
          @close="closeModal"
          @savePdf="savePdf(item)"
          @saveXls="saveXls(item)"
        />
      </template>
    </modal>
  </div>
</template>

<script>
import { getExtendedPdf, getExtendedXls } from '@/api/moderator'
import InlineSvg from 'vue-inline-svg'
import { cloneDeep } from 'lodash'
import { handleError } from '@/utils/deepSettings'
import { cellsKeys, cellsWidths } from '@/utils/dashboardTable'
import AppCheckbox from '@/components/dump/AppCheckbox'
import OptionsButton from '@/components/smart/Dashboard/DashboardTable/OptionsButton'
import TableCell from '@/components/smart/Dashboard/DashboardTable/TableCell'
import AppButton from '@/components/elements/AppButton'
import { mapState } from 'vuex'
import { convertISOtoDate } from '@/utils/DateConverters'
import { applicationData } from '@/utils/InitialDeepSettingsState'
import ShareModalContent from '@/components/smart/DeepSettings/ShareModalContent'
import { saveAs } from 'file-saver'
export default {
  components: {
    AppButton,
    TableCell,
    OptionsButton,
    AppCheckbox,
    InlineSvg,
    ShareModalContent,
    Modal: () => import('@/components/elements/Modals/Modal')
  },
  data: () => ({
    isModalOpen: false,
    modalIsLoading: false,
    urlHash: null
  }),
  props: {
    cellsToDisplay: {
      type: Array,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    selectedApplications: {
      type: Array,
      required: true
    },
    isLoading: Boolean
  },
  name: 'TableBodyRow',
  methods: {
    updateSelectedApplications(val) {
      this.$emit('updateSelectedApplications', val)
    },
    goToDeepSettings() {
      this.$router.push({ name: 'DeepSettings', params: { hash: this.item.hash } })
    },
    closeModal() {
      this.urlHash = null
      this.isModalOpen = false
    },
    showMethod() {
      this.isModalOpen = true
    },
    savePdf(item) {
      this.modalIsLoading = true
      const deepSettings = { ...item }
      const sectors = item.sectors
      const result = item.result
      const hash = item.hash
      getExtendedPdf({
        ...this.pdfOrXlsPayload,
        deepSettings,
        sectors,
        result,
        hash,
        domenName: `${location.origin}/${this.$i18n.locale}`
      })
        .then(response => {
          this.modalIsLoading = false
          const file = new Blob([response.data], { type: 'application/pdf' })
          const fileURL = URL.createObjectURL(file)
          window.open(fileURL)
        })
        .catch(e => {
          handleError(this.$notify, e)
          this.modalIsLoading = false
        })
    },
    saveXls(item) {
      this.modalIsLoading = true
      const deepSettings = { ...item }
      const sectors = item.sectors
      const result = item.result
      const hash = item.hash
      getExtendedXls({
        ...this.pdfOrXlsPayload,
        deepSettings,
        sectors,
        result,
        hash,
        domenName: `${location.origin}/${this.$i18n.locale}`
      })
        .then(response => {
          this.modalIsLoading = false
          const file = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          })
          const newFileName = `Расчет ${item.applicationData.cipher}.xlsx`
          saveAs(file, newFileName)
        })
        .catch(e => {
          handleError(this.$notify, e)
          this.modalIsLoading = false
        })
    }
  },
  computed: {
    ...mapState('moderator', {
      calculationTypes: state => state.calculationTypes,
      customerTypes: state => state.customerTypes,
      applicationData: state => state.applicationData,
      regions: state => state.regions,
      stages: state => state.buildingStages,
      accuracyStepData: state => state.accuracyStepData
    }),
    pdfOrXlsPayload() {
      const { $i18n, regions, stages, calculationTypes, accuracyStepData } = this
      const { locale: lang } = $i18n
      return {
        lang,
        regions,
        stages,
        calculationTypes,
        accuracyStepData
      }
    },
    isSpecialCalculationType() {
      return this.item[applicationData].calculationType !== 372
    },
    normalizedItem() {
      const clonedItem = cloneDeep(this.item)
      const { applicationData, objectData, customerClientData } = clonedItem
      const { customerTypes, calculationTypes } = this
      return {
        [cellsKeys.applicationNumber]: applicationData.applicationNumber,
        [cellsKeys.cipher]: applicationData.cipher,
        [cellsKeys.objectName]: objectData.name,
        [cellsKeys.endDate]: convertISOtoDate(applicationData.endDate),
        [cellsKeys.status]: clonedItem.status,
        [cellsKeys.customer]: customerTypes.find(type => type.id === customerClientData.customer)
          ?.name,
        [cellsKeys.calculationType]: calculationTypes.find(
          type => type.id === applicationData.calculationType
        )?.name
      }
    },
    activeItemCells() {
      return Object.entries(this.normalizedItem).filter(([key]) => {
        return this.cellsToDisplay.includes(key)
      })
    },
    cellsWidths: () => cellsWidths
  }
}
</script>

<style scoped lang="sass">
.table-btns
  display: flex
.table-row
  display: flex
  // transition: .3s
  position: relative
  // &:not(:last-child)
    box-shadow: inset 0 rem(-1) 0 $ec
  // @media(any-hover: hover)
    &:hover
      box-shadow: 0 rem(1) rem(7) rgba(153, 153, 153, 0.35)
      border-radius: rem(2)
</style>
