<template>
  <div :class="classes" v-if="isShowBadge">
    <img :src="statusKit.icon" class="project-status__icon" :alt="statusKit.text" />
    {{ statusKit.text }}
  </div>
  <span v-else> — </span>
</template>

<script>
import { mapState } from 'vuex'

const statusSettings = {
  425: 'green',
  426: 'yellow',
  427: 'red',
  428: 'grey'
}

export default {
  name: 'ProjectStatus',
  props: {
    status: {
      type: [String, Number],
      default: null
    }
  },
  computed: {
    ...mapState('moderator', {
      projectStatuses: state => state.calculationStatus
    }),
    isShowBadge() {
      return this.projectStatuses.flatMap(item => item.id).includes(this.status)
    },
    classes() {
      return ['project-status', `project-status--${this.statusKit.color}`]
    },
    statusKit() {
      const { status } = this
      return {
        icon: require(`@/assets/img/icons/project-statuses/${status}.svg`) || '',
        color: statusSettings[status],
        text: this.projectStatuses.find(s => s.id === status).name
      }
    }
  }
}
</script>

<style scoped lang="sass">
.project-status
  border-radius: rem(2)
  padding: rem(4) rem(8)
  display: inline-flex
  align-items: center
  justify-content: flex-start
  font-style: normal
  font-size: rem(12)
  line-height: 100%
  font-weight: 600
  &__icon
    margin-right: rem(7)
  // &--red
    font-weight: 600
  // &--yellow, &--grey
    font-weight: bold
  &--red
    color: #D1353D
    background: rgba(234, 69, 78, .25)
  &--yellow
    color: #B79418
    background: #FFE382
  &--grey
    color: #A7A395
    background: #EBE8DD
  &--green
    color: $green
    background: #D4F4E2
</style>
